import {
  Header,
  HelpIcon,
  IntegrationIcon,
  IPropsOf,
  Sidebar,
  UserMenu,
  useSidebar,
  useToggle,
  VerificationIcon,
} from '@cincel.digital/design-system';
import { css, cx } from '@emotion/css';
import { useActor } from '@xstate/react';
import Logo from 'assets/img/logo.light.png';
import { IdentityContext } from 'contexts';
import { i18n } from 'locales';
import { MachineNodes } from 'machine';
import { IdentityProvider } from 'providers';
import { forwardRef, useContext, useEffect, useMemo, useRef } from 'react';
import { use100vh } from 'react-div-100vh';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { __DEV__ } from 'utils';

import { IdentityDocument } from './nodes/IdentityDocument';
import { Idle } from './nodes/Idle';
import { IDType } from './nodes/IDType';
import { LifeProof } from './nodes/LifeProof';
import { Middleware } from './nodes/Middleware';
import { Results } from './nodes/Results';
import { UploadMethod } from './nodes/UploadMethod';

const ICON_PROPS = { color: '#FFF', height: 25, width: 25 };

const NAVIGATION = [
  {
    icon: <VerificationIcon {...ICON_PROPS} />,
    isExternal: true,
    label: i18n.t('navigation.blockchain-verification'),
    to: 'https://blockchain.cincel.digital/',
  },
  {
    icon: <IntegrationIcon {...ICON_PROPS} />,
    isExternal: true,
    label: i18n.t('navigation.integrations'),
    to: 'https://app.cincel.digital/integrations',
  },
  {
    icon: <HelpIcon {...ICON_PROPS} />,
    isExternal: true,
    label: i18n.t('navigation.help'),
    routes: [
      {
        isExternal: true,
        label: i18n.t('navigation.help.faqs'),
        to: 'https://help.cincel.digital/preguntas-frecuentes',
      },
      {
        isExternal: true,
        label: i18n.t('navigation.help.guides'),
        to: 'https://help.cincel.digital/guías-rápidas',
      },
      {
        isExternal: true,
        label: i18n.t('navigation.help.tech-support'),
        to: 'https://help.cincel.digital/kb-tickets/new',
      },
    ],
    to: 'https://help.cincel.digital/',
  },
];

const NODES = {
  [MachineNodes.IDLE]: Idle,
  [MachineNodes.SELECT_ID_TYPE]: IDType,
  [MachineNodes.SELECT_UPLOAD_METHOD]: UploadMethod,
  [MachineNodes.IDENTITY_DOCUMENT]: IdentityDocument,
  [MachineNodes.LIFE_PROOF]: LifeProof,
  [MachineNodes.RESULTS]: Results,
  [MachineNodes.VERIFICATION_MIDDLEWARE]: Middleware,
  [MachineNodes.RESULTS_MIDDLEWARE]: Middleware,
};

const Node = forwardRef<HTMLElement, IPropsOf<'section'>>(
  (props, ref): JSX.Element => {
    const context = useContext(IdentityContext);

    const [state] = useActor(context);

    const UI = useMemo(
      () => NODES[state.value as keyof typeof MachineNodes],
      [state?.value],
    );

    return (
      <section ref={ref} {...props}>
        <UI />
      </section>
    );
  },
);

if (__DEV__) {
  Node.displayName = 'Node';
}

export const Home: React.FC = (): JSX.Element => {
  const [isOpen, toggle] = useToggle(false);

  const [sp] = useSearchParams();

  const contentRef = useRef<HTMLDivElement>(null);

  const sidebarRef = useRef<HTMLBaseElement>(null);

  const height = use100vh();

  const { t } = useTranslation();

  const HEIGHT_CLASS = useMemo(
    () =>
      css`
        min-height: ${height}px;
      `,
    [height],
  );

  const SIDEBAR_CLASS = useMemo(
    () =>
      cx(
        'fixed top-0 z-30 transition-[margin] duration-500 lg:sticky lg:ml-0',
        HEIGHT_CLASS,
        isOpen ? 'ml-0' : 'ml-[-320px]',
      ),
    [HEIGHT_CLASS, isOpen],
  );

  useSidebar(sidebarRef, isOpen, toggle);

  useEffect(() => {
    if (sp.has('token')) localStorage.setItem('token', sp.get('token') || '');
    else localStorage.removeItem('token');
  }, []);

  useEffect(() => {
    if (sp.has('uuid')) localStorage.setItem('uuid', sp.get('uuid') || '');
    else localStorage.removeItem('uuid');
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('seo.root')}</title>
      </Helmet>

      <IdentityProvider>
        <main
          className={cx(
            'relative flex flex-col bg-white lg:flex-row',
            HEIGHT_CLASS,
          )}
        >
          <Header
            className="fixed top-0 z-10 w-full lg:hidden"
            logo={Logo}
            onClick={() => toggle()}
          />

          <UserMenu
            className="fixed right-0 top-0 z-10 hidden w-72 bg-white lg:flex"
            onLoginClick={() => window.open('https://app.cincel.digital/login')}
            onSignupClick={() =>
              window.open('https://app.cincel.digital/signup')
            }
            primaryColor="#6344FF"
          />

          <Sidebar
            ref={sidebarRef}
            className={SIDEBAR_CLASS}
            logo={Logo}
            navigation={NAVIGATION}
            onClick={() => window.open('https://app.cincel.digital')}
            primaryColor="#6344FF"
          />

          <div
            className={cx(
              'fixed inset-0 z-20 bg-black opacity-50 lg:hidden',
              !isOpen ? 'hidden' : 'block',
            )}
          />

          <Node
            ref={contentRef}
            className="m-4 mt-20 flex flex-1 flex-col overflow-x-hidden lg:mx-32 lg:my-16 xl:mx-64"
          />
        </main>
      </IdentityProvider>
    </>
  );
};
