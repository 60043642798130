export const translationsEN = {
  // Button translations.
  'button.back': 'Back',
  'button.cancel': 'Cancel',
  'button.next': 'Next',
  'button.repeat': 'Repeat',
  'button.search': 'Search',
  'button.start': 'Start',
  'button.use': 'Use',
  // DnD translations.
  'dnd.drop-file': 'Drop your file.',
  'dnd.empty-container': "There're no files selected.",
  // Error translations.
  'error.file-format': 'This field only allows PNG and JPG files.',
  'error.file-size': 'The max file size allowed is 5MB.',
  'error.required': 'This field is required.',
  // Sidebar navigation translations.
  'navigation.blockchain-verification': 'Verification',
  'navigation.help': 'Help center',
  'navigation.help.faqs': 'FAQs',
  'navigation.help.guides': 'Quick guides',
  'navigation.help.tech-support': 'Tech support',
  'navigation.integrations': 'Integrations',
  // SELECT_ID_TYPE node translations.
  'node.id-type.driver-license': 'Driver license',
  'node.id-type.id': 'Official ID',
  'node.id-type.passport': 'Passport',
  'node.id-type.select-country': 'Select country',
  'node.id-type.select-id-type': 'Select an identification type',
  // IDENTITY_DOCUMENT node translations.
  'node.identity-document.fs.driver-license.back':
    "Upload the back of your driver's license",
  'node.identity-document.fs.driver-license.front':
    "Upload the front of your driver's license",
  'node.identity-document.fs.id.back':
    'Upload the back of your official identification card',
  'node.identity-document.fs.id.front':
    'Upload the front of your official identification card',
  'node.identity-document.fs.passport.front':
    'Upload the front of your passport',
  'node.identity-document.sdk.driver-license.back':
    "Capture the back of your driver's license",
  'node.identity-document.sdk.driver-license.front':
    "Capture the front of your driver's license",
  'node.identity-document.sdk.id.back':
    'Capture the back of your official identification card',
  'node.identity-document.sdk.id.front':
    'Capture the front of your official identification card',
  'node.identity-document.sdk.passport.front':
    'Capture the front of your passport',
  // IDLE node translations.
  'node.idle.terms':
    'I accept that, by allowing the collection of the image of my face and any official identification document, this is the authentication mechanism through which I express my express and written consent so that CINCEL, directly and/or through managers, treat my personal data in accordance with the provisions of the <0>privacy notice</0>.',
  'node.idle.title': 'Identity verification',
  // LIFE_PROOF node translations.
  'node.life-proof.title':
    'To finish validating your identity we need a selfie',
  // MIDDLEWARE node translations.
  'node.middleware.quote':
    'We are validating the documentation provided, please do not close or reload this tab, this could take a few minutes.',
  'node.middleware.title': 'Validation in process',
  // RESULTS node translations.
  'node.results.error.quote': 'Contact your provider to request a new attempt',
  'node.results.error.title': 'We were unable to verify your identity',
  'node.results.success.title':
    'Your information has been successfully validated',
  // SELECT_UPLOAD_METHOD node translations.
  'node.upload-method.file-system': 'Upload files from this device',
  'node.upload-method.sdk': 'Take photo with device camera',
  'node.upload-method.select-method': 'Choose an upload method',
  // Not-Found page translations.
  'not-found.title': 'Resource not-found!',
  // SEO translations.
  'seo.root': 'CINCEL | Identity Verification',
};
