export const translationsES = {
  // Button translations.
  'button.back': 'Regresar',
  'button.cancel': 'Cancelar',
  'button.next': 'Siguiente',
  'button.repeat': 'Repetir',
  'button.search': 'Buscar',
  'button.start': 'Comenzar',
  'button.use': 'Usar',
  // DnD translations.
  'dnd.drop-file': 'Suelta tu archivo aquí.',
  'dnd.empty-container': 'No hay archivos seleccionados.',
  // Error translations.
  'error.file-format': 'Este campo solo acepta archivos PNG y JPG.',
  'error.file-size': 'El tamaño máximo permitido es de 5MB.',
  'error.required': 'Este campo es requerido.',
  // Sidebar navigation translations.
  'navigation.blockchain-verification': 'Verificación',
  'navigation.help': 'Centro de ayuda',
  'navigation.help.faqs': 'Preguntas frecuentes',
  'navigation.help.guides': 'Guías rápidas',
  'navigation.help.tech-support': 'Soporte técnico',
  'navigation.integrations': 'Integraciones',
  // SELECT_ID_TYPE node translations.
  'node.id-type.driver-license': 'Licencía de conducir',
  'node.id-type.id': 'Identificación oficial',
  'node.id-type.passport': 'Pasaporte',
  'node.id-type.select-country': 'Elegir país/región de emisión',
  'node.id-type.select-id-type': 'Seleccione el tipo de identificación',
  // IDENTITY_DOCUMENT node translations.
  'node.identity-document.fs.driver-license.back':
    'Carga la parte trasera de tu licencia de conducir',
  'node.identity-document.fs.driver-license.front':
    'Carga la parte frontal de tu licencia de conducir',
  'node.identity-document.fs.id.back':
    'Carga la parte trasera de tu identificación oficial',
  'node.identity-document.fs.id.front':
    'Carga la parte frontal de tu identificación oficial',
  'node.identity-document.fs.passport.front':
    'Carga la parte frontal de tu pasaporte',
  'node.identity-document.sdk.driver-license.back':
    'Captura la parte trasera de tu licencia de conducir',
  'node.identity-document.sdk.driver-license.front':
    'Captura la parte frontal de tu licencia de conducir',
  'node.identity-document.sdk.id.back':
    'Captura la parte trasera de tu identificación oficial',
  'node.identity-document.sdk.id.front':
    'Captura la parte frontal de tu identificación oficial',
  'node.identity-document.sdk.passport.front':
    'Captura la parte frontal de tu pasaporte',
  // IDLE node translations.
  'node.idle.terms':
    'Acepto que, al permitir la recolección de la imagen de mi rostro y de algun documento de identificación oficial, éste es el mecanismo de autenticación por medio del cual manifiesto mi consentimiento expreso y por escrito para que CINCEL, directa y/o a  través de encargados, trate mis datos personales conforme a lo dispuesto en el <0>aviso de privacidad</0>.',
  'node.idle.title': 'Verificación de identidad',
  // LIFE_PROOF node translations.
  'node.life-proof.title':
    'Para terminar de validar tu identidad necesitamos una selfie',
  // MIDDLEWARE node translations.
  'node.middleware.quote':
    'Estamos validando la documentación proporcionada, por favor no cierres ni recargues esta pestaña, esto podría tomar unos minutos.',
  'node.middleware.title': 'Validación en proceso',
  // RESULTS node translations.
  'node.results.error.quote':
    'Contacta a tu proveedor para solicitar un nuevo intento',
  'node.results.error.title': 'No hemos podido verificar tu identidad',
  'node.results.success.title': 'Tu información ha sido validada exitosamente',
  // SELECT_UPLOAD_METHOD node translations.
  'node.upload-method.file-system': 'Cargar archivos desde este dispositivo',
  'node.upload-method.sdk': 'Tomar foto con la cámara del dispositivo',
  'node.upload-method.select-method': 'Elige un método de carga',
  // Not-Found page translations.
  'not-found.title': '¡Recurso no encontrado!',
  // SEO translations.
  'seo.root': 'CINCEL | Validación de Identidad',
};
