/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelFontSize,
  Image,
  IStackProps,
  Stack,
} from '@cincel.digital/design-system';
import { cx } from '@emotion/css';
import { useMobileDetect } from 'hooks';
import { useValidateId } from 'hooks/useValidateId';
import fp from 'lodash/fp';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useController, useFormContext } from 'react-hook-form';
import { MoonLoader } from 'react-spinners';

import { DndContainer } from './components/DndContainer';

export interface IFileFieldProps extends IStackProps {
  /**
   * Comma-separated list of one or more file types.
   */
  accept?: string;
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
  /**
   * Field helper text that will be displayed below the input.
   */
  helper?: string;
  /**
   * ID type, valid values are: `id`, `passport` and `driver-license`, by
   * default is set to `undefined`.
   */
  idType?: 'id' | 'passport' | 'driver-license';
  /**
   * Field label.
   */
  label: string;
  /**
   * Custom CSS tailwind styles for the label tag related to this input.
   */
  labelClassName?: string;
  /**
   * Label font size, can be one of the following keys:
   * "xs" | "sm" | "md" | "lg" | "xl".
   */
  labelFontSize?: keyof typeof FormLabelFontSize;
  /**
   * Field name that RHF will use to identify the input on the HTML code.
   */
  name: string;
  /**
   * Handle on success
   */
  onSuccess?: () => void;
  /**
   * Handle on error
   */
  onError?: () => void;
}

export const FileField: React.FC<IFileFieldProps> = ({
  accept = '.jpg, .png',
  helper,
  label,
  labelClassName,
  labelFontSize = 'sm',
  name,
  idType,
  onSuccess,
  onError,
  ...rest
}): JSX.Element => {
  const { isDesktop } = useMobileDetect();
  const [isLoading, setIsLoading] = useState(false);

  const { control } = useFormContext();

  const { field, fieldState } = useController({ control, name });

  const { ready, result, startManualCapture } = useValidateId({
    enabled: true,
    onError: () => {
      setIsLoading(false);
      if (onError) onError();
    },
    onFinish: () => {
      setIsLoading(false);
      if (onSuccess) onSuccess();
    },
  });

  const ref = useRef<HTMLInputElement>(null);

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const file = fp.compose(fp.head, fp.get('target.files'))(e);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => field.onChange(file);
    },
    [ref?.current],
  );

  const handleOnClick = useCallback(() => {
    setIsLoading(true);
    startManualCapture();
  }, [ref?.current, ready, isDesktop]);

  useEffect(() => {
    if (result && result.data) {
      const dataURLtoFile = (dataurl: string, filename: string) => {
        const arr = dataurl.split(',');
        const mime = arr?.[0]?.match(/:(.*?);/)?.[1];
        const bstr = atob(arr[arr.length - 1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        // eslint-disable-next-line no-plusplus
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
      };

      const file = dataURLtoFile(result.data, `${idType}.jpg`);
      field.onChange(file);
    }
  }, [result]);

  if (isLoading)
    return (
      <MoonLoader className="mx-auto" color="#C4C4C4" speedMultiplier={0.5} />
    );

  return (
    <DndProvider backend={HTML5Backend}>
      <Stack {...rest}>
        <FormLabel
          className={cx(FormLabelFontSize[labelFontSize], labelClassName)}
          htmlFor={name}
        >
          {label}
        </FormLabel>
        {result ? (
          <Image
            alt=""
            className="m-auto rounded-lg border-4 border-solid border-[#6344FF]"
            src={result?.data}
            width={300}
          />
        ) : (
          <DndContainer
            className="flex-1 items-start"
            onChange={field.onChange}
            onClick={handleOnClick}
            validateImage
            value={field.value}
          />
        )}

        <FormHelperText className="mt-2">{helper}</FormHelperText>

        <FormErrorMessage className="mt-2">
          {fieldState?.error?.message}
        </FormErrorMessage>

        <input
          ref={ref}
          accept={accept}
          className="hidden"
          onChange={handleOnChange}
          type="file"
        />
      </Stack>
    </DndProvider>
  );
};
