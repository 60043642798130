/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

export const CREDENTIALS = {
  password: process.env.REACT_APP_GBG_PASSWORD as string,
  subscription: process.env.REACT_APP_GBG_SUBSCRIPTION_ID as string,
  user: process.env.REACT_APP_GBG_USERNAME as string,
};

export const useFacialRecognition = ({ enabled, onFinish, onError }) => {
  const [captured, setCaptured] = useState(false);
  const [capturing, setCapturing] = useState(false);
  const [error, setError] = useState(false);
  const [result, setResult] = useState<{
    data: string;
    score: number;
  } | null>();

  const [ready, setReady] = useState(false);

  const { AcuantJavascriptWebSdk, AcuantPassiveLiveness } = window as any;

  const faceDetectionStates = {
    FACE_ANGLE_TOO_LARGE: 'FACE ANGLE TOO LARGE',
    FACE_CLOSE_TO_BORDER: 'TOO CLOSE TO THE FRAME',
    FACE_NOT_FOUND: 'FACE NOT FOUND',
    FACE_TOO_SMALL: 'FACE TOO SMALL',
    PROBABILITY_TOO_SMALL: 'PROBABILITY TOO SMALL',
    TOO_MANY_FACES: 'TOO MANY FACES',
  };

  const faceCaptureCallback = {
    onCaptured: (base64Image: string) => {
      setCapturing(false);
      const TEST_ENDPINT = 'https://preview.passlive.acuant.net';
      const TEST_SUB = 'b15c1ca3-e673-4971-a415-e77e109cbc2b';
      const TEST_TOKEN =
        'YjE1YzFjYTMtZTY3My00OTcxLWE0MTUtZTc3ZTEwOWNiYzJiO2lkc2NhbmdvOjJ4QVhQWG5HMVJPNmxsIWo=';
      // const base64Token = btoa(
      //   `${CREDENTIALS.subscription};${CREDENTIALS.user}:${CREDENTIALS.password}`,
      // );

      AcuantPassiveLiveness.getLiveness(
        {
          // endpoint: process.env.REACT_APP_GBG_PASSLIVE_URL,
          endpoint: TEST_ENDPINT,
          image: base64Image,
          subscriptionId: TEST_SUB, // CREDENTIALS.subscription,
          token: TEST_TOKEN, // base64Token,
        },
        (res) => {
          const { LivenessResult } = res;

          setResult({
            data: base64Image,
            score: LivenessResult.Score,
          });

          onFinish();
        },
      );
    },
    onClosed: () => {
      // Event triggered when camera has closed.
    },
    onDetection: () => {
      // Triggered when the face does not pass the scan.
    },
    onDetectorInitialized: () => {
      // This callback is triggered when the face detector is ready. Until then,
      // no actions are executed and the user sees only the camera stream.
    },
    onError: (err: any) => {
      // Error occurred. Camera permission not granted will manifest here with
      // `1` as error code. Unexpected errors will have `2` as error code.
      // eslint-disable-next-line no-console
      console.error(err);
      onError();
      setError(true);
      setCapturing(false);
    },
    onOpened: () => {
      // Event triggered when camera has opened
      setCapturing(true);
    },
    onPhotoRetake: () => {
      // Event triggered when retake button is tapped.
    },
    onPhotoTaken: () => {
      // The photo has been taken and it's showing a preview with a button to
      // accept or retake the image.
    },
    onSuccess: (success: any) => {
      // eslint-disable-next-line no-console
      console.info(success);
      setCaptured(true);
    },
  };

  const getFaceMatch = async () => {
    AcuantPassiveLiveness.start(faceCaptureCallback, faceDetectionStates);
  };

  const clearResult = () => {
    setResult(null);
  };

  useEffect(() => {
    // This is an example of how to detect an older ios device. Depending on
    // your enviroment you might be able to get more specific.
    const isOldiOS = () => {
      const ua = navigator.userAgent;
      let keyPhrase = 'iPhone OS';
      const keyPhrase2 = 'iPad; CPU OS';
      let index = ua.indexOf(keyPhrase);
      if (index < 0) {
        keyPhrase = keyPhrase2;
        index = ua.indexOf(keyPhrase);
      }
      if (index >= 0) {
        const version = ua.substring(
          index + keyPhrase.length + 1,
          index + keyPhrase.length + 3,
        );
        try {
          const versionNum = parseInt(version, 10);
          return versionNum && versionNum < 13;
        } catch (_) {
          return false;
        }
      } else {
        return false;
      }
    };

    const initDone = () => {
      setReady(true);
    };

    const init = () => {
      const base64Token = btoa(`${CREDENTIALS.user}:${CREDENTIALS.password}`);
      AcuantJavascriptWebSdk.initialize(
        base64Token,
        process.env.REACT_APP_GBG_ACAS_URL,
        {
          onFail(code: any, description: any) {
            // Event triggered when an error occurred during initialization.
            // eslint-disable-next-line no-console
            console.error(code, description);
          },
          onSuccess() {
            if (!isOldiOS()) {
              // No list of workers to start, it will start all the workers.
              (window as any).AcuantJavascriptWebSdk.startWorkers(initDone);
            } else {
              // Old iOS devices can struggle running metrics.
              (window as any).AcuantJavascriptWebSdk.startWorkers(initDone, [
                (window as any).AcuantJavascriptWebSdk.ACUANT_IMAGE_WORKER,
              ]);
            }
          },
        },
      );
    };

    const unexpectedError = (err: any) => {
      // Handle an unexpected or unknown error
      // eslint-disable-next-line no-console
      console.error(err);
    };

    const onAcuantSdkLoaded = () => {
      // Unexpected errors shouldn't happen with correct usage of the sdk.
      (window as any).AcuantJavascriptWebSdk.setUnexpectedErrorCallback(
        unexpectedError,
      );
      init();
    };

    if (enabled) {
      onAcuantSdkLoaded();
    }
  }, [enabled]);

  return {
    captured,
    capturing,
    clearResult,
    error,
    getFaceMatch,
    ready,
    result,
  };
};
