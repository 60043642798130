/**
 * Enum for the machine nodes.
 * @readonly
 * @enum {string}
 */
export enum MachineNodes {
  IDLE = 'IDLE',
  SELECT_ID_TYPE = 'SELECT_ID_TYPE',
  SELECT_UPLOAD_METHOD = 'SELECT_UPLOAD_METHOD',
  IDENTITY_DOCUMENT = 'IDENTITY_DOCUMENT',
  LIFE_PROOF = 'LIFE_PROOF',
  RESULTS = 'RESULTS',
  VERIFICATION_MIDDLEWARE = 'VERIFICATION_MIDDLEWARE',
  RESULTS_MIDDLEWARE = 'RESULTS_MIDDLEWARE',
}

/**
 * Enum for the machine services.
 * @readonly
 * @enum {string}
 */
export enum MachineServices {
  VERIFICATION_MIDDLEWARE = 'VERIFICATION_MIDDLEWARE',
  RESULTS_MIDDLEWARE = 'RESULTS_MIDDLEWARE',
}

/**
 * Enum for the machine actions.
 * @readonly
 * @enum {string}
 */
export enum MachineActions {
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
  UPDATE_CONTEXT = 'UPDATE_CONTEXT',
}
