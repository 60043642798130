import { Button } from '@cincel.digital/design-system';
import { yupResolver } from '@hookform/resolvers/yup';
import { SDKField } from 'components';
import { IdentityContext } from 'contexts';
import { i18n } from 'locales';
import fp from 'lodash/fp';
import { IMachineContext } from 'machine';
import { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isValidFileFormat, isValidFileSize } from 'utils';
import * as Yup from 'yup';

const SCHEMA = Yup.object().shape({
  faceImg: Yup.mixed()
    .test('file-size', i18n.t('error.file-size') as string, (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidFileSize((v as File)?.size);
    })
    .test('file-format', i18n.t('error.file-format') as string, (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidFileFormat((v as File)?.type);
    })
    .required(i18n.t('error.required') as string),
});

type IFormValues = Pick<IMachineContext, 'faceImg'>;

export const LifeProof = (): JSX.Element => {
  const context = useContext(IdentityContext);

  const methods = useForm<IFormValues>({
    defaultValues: { faceImg: undefined },
    mode: 'all',
    resolver: yupResolver(SCHEMA),
  });

  const { t } = useTranslation();

  const handleOnSubmit = useCallback(
    (v: IFormValues) => {
      context?.send('NEXT', v);
    },
    [context?.send],
  );

  return (
    <FormProvider {...methods}>
      <form
        className="relative flex flex-1 flex-col justify-center gap-4"
        onSubmit={methods.handleSubmit(handleOnSubmit)}
      >
        <SDKField label={t('node.life-proof.title')} name="faceImg" />

        <div className="mt-3 flex justify-end">
          <Button
            className="px-6 text-white"
            isDisabled={!methods.formState.isValid}
            primaryColor="#6344FF"
            size="sm"
            type="submit"
            variant="solid"
          >
            {t('button.next')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
