import { Button } from '@cincel.digital/design-system';
import { RadioCardField } from 'components';
import { IdentityContext } from 'contexts';
import { useMobileDetect } from 'hooks';
import { IMachineContext } from 'machine';
import { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaCamera, FaFileUpload } from 'react-icons/fa';

type IFormValues = Pick<IMachineContext, 'uploadMethod'>;

export const UploadMethod = (): JSX.Element => {
  const { isDesktop } = useMobileDetect();
  const context = useContext(IdentityContext);

  const methods = useForm<IFormValues>({
    defaultValues: { uploadMethod: isDesktop() ? 'file-system' : 'sdk' },
  });

  const { t } = useTranslation();

  const handleOnSubmit = useCallback(
    (v: IFormValues) => {
      context?.send('NEXT', v);
    },
    [context?.send],
  );

  return (
    <FormProvider {...methods}>
      <form
        className="relative flex flex-1 items-center"
        onSubmit={methods.handleSubmit(handleOnSubmit)}
      >
        <fieldset className="flex flex-1 flex-col gap-4">
          <legend className="mb-4 text-xl font-bold">
            {t('node.upload-method.select-method')}
          </legend>

          {!isDesktop() ? (
            <RadioCardField
              icon={FaCamera}
              label={t('node.upload-method.sdk')}
              name="uploadMethod"
              value="sdk"
            />
          ) : null}

          {isDesktop() ? (
            <RadioCardField
              icon={FaFileUpload}
              label={t('node.upload-method.file-system')}
              name="uploadMethod"
              value="file-system"
            />
          ) : null}
        </fieldset>

        <div className="absolute bottom-0 right-0">
          <Button
            className="px-6 text-white"
            primaryColor="#6344FF"
            size="sm"
            type="submit"
            variant="solid"
          >
            {t('button.next')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
