import { CheckIcon, H1, WarningIcon } from '@cincel.digital/design-system';
import { useActor } from '@xstate/react';
import { IdentityContext } from 'contexts';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const Results = (): JSX.Element => {
  const context = useContext(IdentityContext);

  const [state] = useActor(context);

  const { t } = useTranslation();

  const Icon = useMemo(
    () => (state?.context?.error ? WarningIcon : CheckIcon),
    [state?.context.error],
  );

  const iconColor = useMemo(
    () => (state?.context?.error ? 'red' : 'green'),
    [state?.context.error],
  );

  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-4">
      <Icon color={iconColor} height={128} width={128} />

      <H1 className="text-center">
        {t(
          state?.context?.error
            ? 'node.results.error.title'
            : 'node.results.success.title',
        )}
      </H1>

      {state?.context?.error ? (
        <p className="-mt-4 text-center text-sm md:max-w-2xl">
          {t('node.results.error.quote')}
        </p>
      ) : null}
    </div>
  );
};
