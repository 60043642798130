import { Button } from '@cincel.digital/design-system';
import { RadioCardField, SelectField } from 'components';
import { IdentityContext } from 'contexts';
import { IMachineContext } from 'machine';
import { useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaIdCard, FaIdCardAlt, FaPassport } from 'react-icons/fa';

interface IFormValues extends Pick<IMachineContext, 'idType'> {
  /**
   * User can select its nationality, in case that is Mexico, then the form
   * will hide the `driver-license` radio button option, otherwise it will be
   * available.
   */
  country: string;
}

export const IDType = (): JSX.Element => {
  const context = useContext(IdentityContext);

  const methods = useForm<IFormValues>({
    defaultValues: { country: 'MX', idType: 'passport' },
  });

  const [countries, setCountries] = useState([]);

  const { t } = useTranslation();

  const handleOnSubmit = useCallback(
    (v: IFormValues) => {
      context?.send('NEXT', { idType: v.idType });
    },
    [context?.send],
  );

  const watchNationality = methods.watch('country');

  useEffect(() => {
    const trx = ({ alpha2Code, name }) => ({ label: name, value: alpha2Code });
    fetch('https://restcountries.com/v2/all')
      .then((res) => res.json())
      .then((d) => d?.map(trx))
      .then((d) => setCountries(d));
  }, []);

  return (
    <FormProvider {...methods}>
      <form
        className="relative flex flex-1 flex-col justify-center gap-4"
        onSubmit={methods.handleSubmit(handleOnSubmit)}
      >
        <SelectField
          className="lg:mb-16"
          label={t('node.id-type.select-country')}
          labelClassName="mb-4 text-xl !font-bold !text-black"
          name="country"
          options={countries}
          selectClassName="max-w-full lg:max-w-sm"
          size="md"
        />

        <fieldset className="flex flex-col gap-4">
          <legend className="mb-4 text-xl font-bold">
            {t('node.id-type.select-id-type')}
          </legend>

          <RadioCardField
            icon={FaPassport}
            label={t('node.id-type.passport')}
            name="idType"
            value="passport"
          />

          <RadioCardField
            icon={FaIdCard}
            label={t('node.id-type.id')}
            name="idType"
            value="id"
          />

          {watchNationality !== 'MX' ? (
            <RadioCardField
              icon={FaIdCardAlt}
              label={t('node.id-type.driver-license')}
              name="idType"
              value="driver-license"
            />
          ) : null}
        </fieldset>

        <div className="absolute bottom-0 right-0">
          <Button
            className="px-6 text-white"
            primaryColor="#6344FF"
            size="sm"
            type="submit"
            variant="solid"
          >
            {t('button.next')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
