export interface IMachineContext {
  /**
   * ID back-side image, by default is `undefined`, user should upload or take
   * a picture of its ID at some point of its validation process. This value
   * is optional since `passport` validation does not requires it.
   */
  backImg?: File;
  /**
   * In case of an error during WRITE operations it will inform to the user if
   * necessary, default value is `false`.
   */
  error: boolean;
  /**
   * Image that the identity verification API will use for face-match, by
   * default is `undefined`. This value is required by the API.
   */
  faceImg?: File;
  /**
   * ID front-side image, by default is `undefined`, user must upload or take
   * a picture of its ID at some point of its validation process. This value
   * is required by the API.
   */
  frontImg?: File;
  /**
   * ID type, valid values are: `id`, `passport` and `driver-license`, by
   * default is set to `undefined`. User must select one of the valid values at
   * some point of its validation process.
   */
  idType?: 'id' | 'passport' | 'driver-license';
  /**
   * Upload method, valid values are: `file-system` and `sdk`, by default is
   * set to `undefined`. User must choose the way that it will upload the data
   * for identity verification process.
   */
  uploadMethod?: 'file-system' | 'sdk';
  /**
   * Identity verification unique identifier, this value comes from the API when
   * the user invokes the verification URL endpoint, this application gets the
   * value from the search-params in the IDLE node and store it in the machine
   * context when transitioning to the SELECT_ID_TYPE node. This value will be
   * used to update the verification resource and retrieve the final results,
   * the default value is `undefined`.
   */
  uuid?: string;
}

export const INITIAL_CONTEXT: IMachineContext = {
  backImg: undefined,
  error: false,
  faceImg: undefined,
  frontImg: undefined,
  idType: undefined,
  uploadMethod: undefined,
  uuid: undefined,
};
