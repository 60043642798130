export const TRANSLATIONS = {
  DESKTOP: {
    SUBTITLE: 'identity.desktop.subtitle',
    TITLE: 'identity.desktop.title',
  },
  ERRORS: {
    EXPIRED: 'identity.error.expired',
    GENERAL: 'identity.error.general_error',
    MALFORMED: 'identity.error.malformed',
  },
  LINK_USED: {
    BUTTON: 'identity.link_used.title',
    SUBTITLE: 'identity.link_used.subtitle',
    TITLE: 'identity.link_used.title',
  },
  PRIVACY_POLICY: 'identity.privacy_policy',
};
