import axios from 'axios';
import fp from 'lodash/fp';
import { assign, createMachine } from 'xstate';

import { IMachineContext, INITIAL_CONTEXT } from './machine.context';
import { IMachineEvents } from './machine.events';
import {
  MachineActions,
  MachineNodes,
  MachineServices,
} from './machine.helpers';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const Machine = createMachine<IMachineContext, IMachineEvents>(
  {
    context: INITIAL_CONTEXT,
    id: 'IDENTITY_VERIFICATION_MACHINE',
    initial: MachineNodes.IDLE,
    predictableActionArguments: true,
    states: {
      [MachineNodes.IDLE]: {
        on: {
          NEXT: {
            actions: [MachineActions.UPDATE_CONTEXT],
            target: MachineNodes.SELECT_ID_TYPE,
          },
        },
      },
      [MachineNodes.SELECT_ID_TYPE]: {
        on: {
          NEXT: {
            actions: [MachineActions.UPDATE_CONTEXT],
            target: MachineNodes.SELECT_UPLOAD_METHOD,
          },
        },
      },
      [MachineNodes.SELECT_UPLOAD_METHOD]: {
        on: {
          NEXT: {
            actions: [MachineActions.UPDATE_CONTEXT],
            target: MachineNodes.IDENTITY_DOCUMENT,
          },
        },
      },
      [MachineNodes.IDENTITY_DOCUMENT]: {
        on: {
          BACK: {
            target: MachineNodes.SELECT_UPLOAD_METHOD,
          },
          NEXT: {
            actions: [MachineActions.UPDATE_CONTEXT],
            target: MachineNodes.LIFE_PROOF,
          },
        },
      },
      [MachineNodes.LIFE_PROOF]: {
        on: {
          NEXT: {
            actions: [MachineActions.UPDATE_CONTEXT],
            target: MachineNodes.VERIFICATION_MIDDLEWARE,
          },
        },
      },
      [MachineNodes.VERIFICATION_MIDDLEWARE]: {
        on: {
          NEXT: {
            actions: [MachineActions.SUCCESS],
            target: MachineNodes.RESULTS_MIDDLEWARE,
          },
        },
        // invoke: {
        //   id: MachineServices.VERIFICATION_MIDDLEWARE,
        //   onDone: {
        //     actions: [MachineActions.SUCCESS],
        //     target: MachineNodes.RESULTS_MIDDLEWARE,
        //   },
        //   onError: {
        //     actions: [MachineActions.FAILURE],
        //     target: MachineNodes.LIFE_PROOF,
        //   },
        //   src: MachineServices.VERIFICATION_MIDDLEWARE,
        // },
      },
      [MachineNodes.RESULTS_MIDDLEWARE]: {
        invoke: {
          id: 'RESULTS_MIDDLEWARE',
          onDone: {
            actions: [MachineActions.SUCCESS],
            target: MachineNodes.RESULTS,
          },
          onError: {
            actions: [MachineActions.FAILURE],
            target: MachineNodes.RESULTS,
          },
          src: MachineServices.RESULTS_MIDDLEWARE,
        },
      },
      [MachineNodes.RESULTS]: {
        type: 'final',
      },
    },
  },
  {
    actions: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      [MachineActions.FAILURE]: assign((ctx, _evt) => ({
        ...ctx,
        error: true,
      })),
      [MachineActions.SUCCESS]: assign((ctx) => ({ ...ctx, error: false })),
      [MachineActions.UPDATE_CONTEXT]: assign((ctx, evt) => ({
        ...ctx,
        ...fp.omit('type')(evt),
      })),
    },
    services: {
      [MachineServices.VERIFICATION_MIDDLEWARE]: async (ctx) => {
        const fd = new FormData();

        const fileType = ctx.idType === 'passport' ? 'PASSPORT' : 'ID';

        if (ctx?.frontImg)
          fd.append('front_side_image', ctx.frontImg, ctx.frontImg.name);
        if (ctx?.backImg) {
          fd.append('back_side_image', ctx.backImg, ctx.backImg.name);
        } else {
          // eslint-disable-next-line no-lonely-if
          if (ctx?.frontImg) {
            fd.append('back_side_image', ctx.frontImg, ctx.frontImg.name);
          }
        }
        if (ctx?.faceImg)
          fd.append('selfie_image', ctx.faceImg, ctx.faceImg.name);
        fd.append('type', fileType);

        try {
          await instance.patch(`/gbg/${ctx?.uuid}/verification`, fd, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data',
            },
          });
        } catch (error) {
          throw new Error(error as any);
        }
      },
      [MachineServices.RESULTS_MIDDLEWARE]: async (ctx) => {
        await instance.get(`/gbg/${ctx?.uuid}/results`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        });
      },
    },
  },
);
