/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, IPropsOf } from '@cincel.digital/design-system';
import { cx } from '@emotion/css';
import { clomp } from 'clomp';
import fp from 'lodash/fp';
import { useCallback, useMemo } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';

const Container = clomp.div`
  border-2
  border-dashed
  border-gray-300
  flex
  flex-1
  flex-col
  h-full
  items-center
  justify-center
  p-4
  rounded-2xl
  w-full
`;

export interface IDndContainerProps extends IPropsOf<'div'> {
  /**
   * On change event that will mutate the RHF state.
   */
  onChange: (...event: any[]) => void;
  /**
   * On click event that will trigger the file API select method.
   */
  onClick: () => void;
  /**
   * Used for validate image, will disable drag and drop.
   */
  validateImage?: boolean;
  /**
   * Current input value.
   */
  value?: File;
}

export const DndContainer: React.FC<IDndContainerProps> = ({
  className,
  onChange,
  onClick,
  validateImage = false,
  value,
  ...rest
}): JSX.Element => {
  const [{ canDrop, isOver }, dragRef] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      collect: (monitor: DropTargetMonitor) => ({
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
      }),
      drop({ files }: { files: File[] }) {
        if (!validateImage) {
          fp.compose(onChange, fp.head)(files);
        }
      },
    }),
    [],
  );

  const { t } = useTranslation();

  const isActive = useMemo(() => canDrop && isOver, [canDrop, isOver]);

  const getLabel = useCallback(() => {
    if (isActive) return t('dnd.drop-file');
    if (fp.isNil(value)) return t('dnd.empty-container');
    return value?.name;
  }, [isActive, t, value]);

  return (
    <Container
      ref={dragRef}
      className={cx({ 'border-gray-300 bg-gray-300/10': isActive }, className)}
      {...rest}
    >
      <p className="text-center text-sm font-medium text-gray-500">
        {getLabel()}
      </p>

      <Button
        className="mt-4 px-6 text-sm text-white"
        onClick={onClick}
        primaryColor="#6344FF"
        size="xs"
        variant="solid"
      >
        {t('button.search')}
      </Button>
    </Container>
  );
};
