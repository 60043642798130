import {
  Button,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelFontSize,
  Image,
  IStackProps,
  Stack,
  useToggle,
} from '@cincel.digital/design-system';
import { cx } from '@emotion/css';
import { clomp } from 'clomp';
import { useFacialRecognition, useMobileDetect, useValidateId } from 'hooks';
import fp from 'lodash/fp';
import { useEffect, useRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MoonLoader } from 'react-spinners';

const Container = clomp.div`
  border-2
  border-dashed
  border-gray-300
  flex
  flex-1
  flex-col
  h-full
  items-center
  justify-center
  p-4
  rounded-2xl
  w-full
`;

export interface ISDKFieldProps extends IStackProps {
  /**
   * Custom CSS tailwind styles.
   */
  className?: string;
  /**
   * Field helper text that will be displayed below the input.
   */
  helper?: string;
  /**
   * Field label.
   */
  label: string;
  /**
   * Custom CSS tailwind styles for the label tag related to this input.
   */
  labelClassName?: string;
  /**
   * Label font size, can be one of the following keys:
   * "xs" | "sm" | "md" | "lg" | "xl".
   */
  labelFontSize?: keyof typeof FormLabelFontSize;
  /**
   * Field name that RHF will use to identify the input on the HTML code.
   */
  name: string;
  /**
   * Handle on success
   */
  onSuccess?: (file: File) => void;
  /**
   * Handle on error
   */
  onError?: () => void;
}

export const SDKField: React.FC<ISDKFieldProps> = ({
  helper,
  label,
  labelClassName,
  labelFontSize = 'sm',
  name,
  onSuccess,
  onError,
  ...rest
}): JSX.Element => {
  const { control } = useFormContext();

  const fileRef = useRef<File | null>(null);

  const isId = name === 'frontImg' || name === 'backImg';

  const isFr = name === 'faceImg';

  const {
    ready: idReady,
    result: idResult,
    startCamera,
    capturing,
    cameraOpened,
  } = useValidateId({
    enabled: isId,
    onError: () => {
      if (onError) onError();
    },
    onFinish: () => {},
  });

  const {
    result: frResult,
    getFaceMatch,
    ready: frReady,
    capturing: frCapturing,
    clearResult,
  } = useFacialRecognition({
    enabled: isFr,
    onError: () => {
      if (onError) onError();
    },
    onFinish: () => {},
  });

  const { field, fieldState } = useController({ control, name });

  const [isOpen, toggle] = useToggle();

  const ref = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const { isDesktop } = useMobileDetect();

  useEffect(() => {
    if (isOpen && !isDesktop() && isId && !capturing) {
      startCamera();
    }
  }, [idReady, isDesktop, isOpen, isId, capturing]);

  useEffect(() => {
    if (isOpen && isFr) {
      getFaceMatch();
    }
  }, [frReady, isOpen, isFr]);

  const handleFocusBack = () => {
    window.removeEventListener('focus', handleFocusBack);
  };

  const clickedFileInput = () => {
    window.addEventListener('focus', handleFocusBack);
  };

  const fileInputClicked = () => {
    window.removeEventListener('focus', handleFocusBack);
  };

  const handleUsePhoto = () => {
    const image =
      name === 'faceImg'
        ? `data:image/jpeg;base64,${frResult?.data}`
        : idResult?.data;

    const dataURLtoFile = (dataurl, filename) => {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[arr.length - 1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      // eslint-disable-next-line no-plusplus
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    };

    const file = dataURLtoFile(image, `${name}.jpg`);
    field.onChange(file);
    fileRef.current = file;
    toggle();
    fileInputClicked();

    if (onSuccess && file) onSuccess(file);
  };

  const showLoading = () => {
    if (idResult || frResult) {
      return false;
    }

    if (capturing || frCapturing) {
      return true;
    }

    return false;
  };

  return (
    <Stack {...rest}>
      <Container>
        <FormLabel
          className={cx(FormLabelFontSize[labelFontSize], labelClassName)}
          htmlFor={name}
        >
          {label}
        </FormLabel>
        {typeof frResult !== 'undefined' && <p>Prueba de vida cargada</p>}
        {!isOpen && (
          <Button
            className="mt-4 px-6 text-sm text-white"
            onClick={() => {
              toggle();
              clickedFileInput();
            }}
            primaryColor="#6344FF"
            size="xs"
            variant="solid"
          >
            {t(!field.value ? 'button.start' : 'button.repeat')}
          </Button>
        )}

        {isOpen && (
          <div className="h-full min-h-[500px] px-8 py-4">
            {typeof idResult === 'undefined' && cameraOpened && (
              <div
                id="acuant-camera"
                style={{
                  display: 'block',
                }}
              />
            )}

            {typeof frResult === 'undefined' && (
              <div
                id="acuant-face-capture-container"
                style={{
                  display: 'block',
                }}
              />
            )}
            {showLoading() && (
              <MoonLoader
                className="mx-auto"
                color="#C4C4C4"
                speedMultiplier={0.5}
              />
            )}

            {idResult ? (
              <Image
                alt="Result ID Image"
                className="h-full w-full"
                id="result-id-img"
                src={idResult.data}
              />
            ) : null}

            {frResult ? (
              <div>
                <Image
                  alt="Facial Recognition Result Image"
                  className="h-full w-full"
                  id="result-fr-img"
                  src={`data:image/jpeg;base64,${frResult.data}`}
                />
              </div>
            ) : null}

            {(idResult || frResult) && (
              <div className="flex justify-between">
                <Button
                  className="mt-4 px-6 text-sm text-white"
                  onClick={() => {
                    clearResult();
                    handleFocusBack();
                    toggle();
                  }}
                  primaryColor="#C4C4C4"
                  size="xs"
                  variant="solid"
                >
                  {t('button.cancel')}
                </Button>

                <Button
                  className="mt-4 px-6 text-sm text-white"
                  isDisabled={!(!fp.isNil(frResult) || !fp.isNil(idResult))}
                  onClick={() => handleUsePhoto()}
                  primaryColor="#6344FF"
                  size="xs"
                  variant="solid"
                >
                  {t('button.use')}
                </Button>
              </div>
            )}
          </div>
        )}
      </Container>

      <FormHelperText className="mt-2">{helper}</FormHelperText>

      <FormErrorMessage className="mt-2">
        {fieldState?.error?.message}
      </FormErrorMessage>

      <input ref={ref} className="hidden" type="file" />

      {/* <Modal isCentered isOpen={isOpen} onClose={toggle} size="lg">

      </Modal> */}
    </Stack>
  );
};
