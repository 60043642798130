import {
  FormErrorMessage,
  FormHelperText,
  IPropsOf,
  Stack,
} from '@cincel.digital/design-system';
import { cx } from '@emotion/css';
import { DefaultTFuncReturn } from 'i18next';
import { useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { IconType } from 'react-icons';

export interface IRadioCardFieldProps extends IPropsOf<'input'> {
  /**
   * Field helper text that will be displayed below the input.
   */
  helper?: DefaultTFuncReturn | string;
  /**
   * Icon that will be rendered on the left side of the input's content.
   */
  icon: IconType;
  /**
   * Form label.
   */
  label: DefaultTFuncReturn | string;
  /**
   * Custom CSS tailwind styles for the label tag related to this input.
   */
  labelClassName?: string;
  /**
   * Field name that RHF will use to identify the input on the HTML code.
   */
  name: string;
}

export const RadioCardField: React.FC<IRadioCardFieldProps> = ({
  className,
  helper,
  icon: Icon,
  label,
  labelClassName,
  name,
  value,
  ...rest
}): JSX.Element => {
  const { control } = useFormContext();

  const {
    field: { onChange, ...field },
    fieldState,
  } = useController({ control, name });

  const CARD_CLASS = useMemo(
    () =>
      cx(
        'cursor-pointer flex items-center gap-4 rounded-lg border-2 p-4 transition-all duration-300 ease-in-out hover:bg-gray-50',
        field?.value === value ? 'border-[#6344FF]' : 'border-[#C4C4C4]',
      ),
    [value, field?.value],
  );

  return (
    <Stack className={className}>
      <label>
        <input
          className="hidden"
          onChange={() => onChange(value)}
          type="radio"
          {...rest}
          {...field}
        />
        <div className={CARD_CLASS}>
          <Icon color="#C4C4C4" size={24} />
          <p className={cx('font-medium', labelClassName)}>{label}</p>
        </div>
      </label>

      <FormHelperText className="mt-2">{helper}</FormHelperText>

      <FormErrorMessage className="mt-2">
        {fieldState?.error?.message}
      </FormErrorMessage>
    </Stack>
  );
};
