/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

export const CREDENTIALS = {
  password: process.env.REACT_APP_GBG_PASSWORD as string,
  subscription: process.env.REACT_APP_GBG_SUBSCRIPTION_ID as string,
  user: process.env.REACT_APP_GBG_USERNAME as string,
};

export const useValidateId = ({ enabled, onFinish, onError }) => {
  const [capturing, setCapturing] = useState(false);
  const [cameraOpened, setCameraOpened] = useState(false);
  const [result, setResult] = useState<{
    data: string;
    height: number;
    width: number;
    cardType: number;
  }>();
  const [ready, setReady] = useState(false);

  const [cardType, setCardType] = useState<number>();

  const [error, setError] = useState<string | undefined>();

  const { AcuantCamera, AcuantJavascriptWebSdk, AcuantCameraUI } =
    window as any;

  const drawImageOnResult = (response: any) => {
    setResult(response);
    onFinish();
  };

  const onCaptured = () => {
    // eslint-disable-next-line no-console
    // alert(JSON.stringify(response));
    setCapturing(true);
    setCameraOpened(false);
    // onFinish();
  };

  const onCropped = (response: any) => {
    onFinish();
    // alert(JSON.stringify(response));
    if (response && response.image) {
      drawImageOnResult(response.image);
      const { cardtype } = response;

      setCardType(cardtype);
      setCapturing(false);
      setCameraOpened(false);
    } else {
      // eslint-disable-next-line no-console
      console.error('error', response);
    }
  };

  const cameraCallback = {
    onCaptured,
    onCropped,
    onError: () => {
      onError();
      // alert(`Error: ${JSON.stringify(err)}`);
    },
    onFrameAvailable(response: any) {
      // Get each frame if needed.
      // eslint-disable-next-line no-console
      console.info(response);
    },
  };

  const startManualCapture = () => {
    AcuantCamera.startManualCapture(cameraCallback);
  };

  const startEvaluateImage = (img) => {
    const byteCharacters = atob(img.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8ClampedArray(byteNumbers);

    AcuantCamera.evaluateImage(
      {
        barcodeText: undefined,
        bytes: byteArray,
        data: img.split(',')[1],
        height: 250,
        width: 300,
      }, // received from trigger capture
      300, // received from trigger capture
      250, // received from trigger capture
      false,
      'MANUAL',
      onCaptured, // shown below
    );
  };

  const hasError = (err: any, code: any) => {
    // eslint-disable-next-line no-console

    if (code === AcuantJavascriptWebSdk.REPEAT_FAIL_CODE) {
      // Live capture was started after previous failure leading to manual
      // capture, show any elements you may have hidden as the user can cancel
      // the prompt and could otherwise end up on a blank page.
    } else {
      switch (code) {
        // Camera froze/crashed; usually happens due to an iOS 15 bug.
        case AcuantJavascriptWebSdk.SEQUENCE_BREAK_CODE:
          setError('Live Camera failed. Start manual capture.');
          break;
        // Camera not supported or permission not granted.
        case AcuantJavascriptWebSdk.START_FAIL_CODE:
          setError('Live Camera failed to open. Start manual capture.');
          break;
        // Unknown error (shouldn't happen).
        default:
          setError('Unknown camera failure. Start manual capture.');
          break;
      }
    }
  };

  const options = {
    text: {
      BIG_DOCUMENT: 'Muy cerca',
      CAPTURING: 'Capturando',
      GOOD_DOCUMENT: null,
      NONE: 'Alinea el documento',
      SMALL_DOCUMENT: 'Acerca el documento',
      TAP_TO_CAPTURE: 'Click para capturar',
    },
  };

  const startCamera = () => {
    document.cookie = 'AcuantCameraHasFailed= =; Max-Age=-99999999;';
    setCameraOpened(true);
    if (AcuantCamera.isCameraSupported) {
      AcuantCameraUI.start(cameraCallback, hasError, options);
    } else {
      startManualCapture();
    }
  };

  useEffect(() => {
    // This is an example of how to detect an older ios device. Depending on
    // your enviroment you might be able to get more specific.
    const isOldiOS = () => {
      const ua = navigator.userAgent;
      let keyPhrase = 'iPhone OS';
      const keyPhrase2 = 'iPad; CPU OS';
      let index = ua.indexOf(keyPhrase);
      if (index < 0) {
        keyPhrase = keyPhrase2;
        index = ua.indexOf(keyPhrase);
      }
      if (index >= 0) {
        const version = ua.substring(
          index + keyPhrase.length + 1,
          index + keyPhrase.length + 3,
        );
        try {
          const versionNum = parseInt(version, 10);
          return versionNum && versionNum < 13;
        } catch (_) {
          return false;
        }
      } else {
        return false;
      }
    };

    const initDone = () => {
      setReady(true);
    };

    const init = () => {
      const TEST_TOKEN =
        'YjE1YzFjYTMtZTY3My00OTcxLWE0MTUtZTc3ZTEwOWNiYzJiO2lkc2NhbmdvOjJ4QVhQWG5HMVJPNmxsIWo=';
      const TEST_URL = 'https://preview.acas.acuant.net';
      // const base64Token = btoa(`${CREDENTIALS.user}:${CREDENTIALS.password}`);
      AcuantJavascriptWebSdk.initialize(
        TEST_TOKEN,
        // process.env.REACT_APP_GBG_ACAS_URL,
        TEST_URL,
        {
          onFail(code: any, description: any) {
            // Event triggered when an error occurred during initialization.
            // eslint-disable-next-line no-console
            console.error(code, description);
          },
          onSuccess() {
            if (!isOldiOS()) {
              // No list of workers to start, it will start all the workers.
              (window as any).AcuantJavascriptWebSdk.startWorkers(initDone);
            } else {
              // Old iOS devices can struggle running metrics.
              (window as any).AcuantJavascriptWebSdk.startWorkers(initDone, [
                (window as any).AcuantJavascriptWebSdk.ACUANT_IMAGE_WORKER,
              ]);
            }
          },
        },
      );
    };

    const unexpectedError = (err: any) => {
      // Handle an unexpected or unknown error.
      // eslint-disable-next-line no-console
      console.error(err);
    };

    const onAcuantSdkLoaded = () => {
      // Unexpected errors shouldn't happen with correct usage of the sdk.
      (window as any).AcuantJavascriptWebSdk.setUnexpectedErrorCallback(
        unexpectedError,
      );
      init();
    };

    if (enabled) {
      onAcuantSdkLoaded();
    }
  }, [enabled]);

  return {
    cameraOpened,
    capturing,
    cardType,
    error,
    ready,
    result,
    startCamera,
    startEvaluateImage,
    startManualCapture,
  };
};
