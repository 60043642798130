import {
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelFontSize,
  ISelectProps,
  IStackProps,
  Select,
  Stack,
} from '@cincel.digital/design-system';
import { cx } from '@emotion/css';
import { DefaultTFuncReturn } from 'i18next';
import fp from 'lodash/fp';
import { useController, useFormContext } from 'react-hook-form';

type ISelectFieldBase = Omit<IStackProps, 'defaultValue'> &
  Omit<ISelectProps, 'primaryColor'>;

export interface ISelectFieldProps extends ISelectFieldBase {
  /**
   * Field helper text that will be displayed below the input.
   */
  helper?: DefaultTFuncReturn | string;
  /**
   * Field label.
   */
  label?: DefaultTFuncReturn | string;
  /**
   * Custom CSS tailwind styles for the label tag related to this input.
   */
  labelClassName?: string;
  /**
   * Field name that RHF will use to identify the input on the HTML code.
   */
  name: string;
  /**
   * Custom CSS tailwind styles for the select tag.
   */
  selectClassName?: string;
}

export const SelectField: React.FC<ISelectFieldProps> = ({
  defaultValue,
  helper,
  isDisabled,
  isLoading,
  isMulti,
  isRequired,
  isRounded,
  label,
  labelClassName,
  menuPlacement,
  name,
  options,
  placeholder,
  selectClassName,
  size = 'sm',
  ...rest
}): JSX.Element => {
  const { control } = useFormContext();

  const { field, fieldState } = useController({ control, defaultValue, name });

  return (
    <Stack {...rest}>
      {!fp.isNil(label) && !isRequired ? (
        <FormLabel
          className={cx(FormLabelFontSize[size], labelClassName)}
          htmlFor={name}
        >
          {label}
        </FormLabel>
      ) : null}

      <Select
        className={selectClassName}
        id={name}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={isMulti}
        isRequired={isRequired}
        isRounded={isRounded}
        menuPlacement={menuPlacement}
        options={options}
        placeholder={placeholder}
        primaryColor="#6344FF"
        size={size}
        {...field}
      />

      <FormHelperText className="mt-2">{helper}</FormHelperText>

      <FormErrorMessage className="mt-2">
        {fieldState?.error?.message}
      </FormErrorMessage>
    </Stack>
  );
};
