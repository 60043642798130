import { Button } from '@cincel.digital/design-system';
import { cx } from '@emotion/css';
import { yupResolver } from '@hookform/resolvers/yup';
import { useActor } from '@xstate/react';
import { FileField, SDKField } from 'components';
import { IdentityContext } from 'contexts';
import { i18n } from 'locales';
import fp from 'lodash/fp';
import { IMachineContext } from 'machine';
import { useCallback, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isValidFileFormat, isValidFileSize } from 'utils';
import * as Yup from 'yup';

const SCHEMA = Yup.object().shape({
  backImg: Yup.mixed().when('idType', {
    is: (v: string) => v === 'id' || v === 'driver-license',
    otherwise: (schema) => schema.nullable().optional(),
    then: (schema) =>
      schema
        .test('file-size', i18n.t('error.file-size') as string, (v) => {
          if (fp.isNil(v)) return true;
          if (fp.isString(v)) return true;
          return isValidFileSize((v as File)?.size);
        })
        .test('file-format', i18n.t('error.file-format') as string, (v) => {
          if (fp.isNil(v)) return true;
          if (fp.isString(v)) return true;
          return isValidFileFormat((v as File)?.type);
        })
        .required(i18n.t('error.required') as string),
  }),

  frontImg: Yup.mixed()
    .test('file-size', i18n.t('error.file-size') as string, (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidFileSize((v as File)?.size);
    })
    .test('file-format', i18n.t('error.file-format') as string, (v) => {
      if (fp.isNil(v)) return true;
      if (fp.isString(v)) return true;
      return isValidFileFormat((v as File)?.type);
    })
    .required(i18n.t('error.required') as string),
});

type IFormValues = Pick<IMachineContext, 'backImg' | 'frontImg' | 'idType'>;

export const IdentityDocument = (): JSX.Element => {
  const [firstPhotoUploaded, setFirstPhotoUploaded] = useState(false);
  const [secondPhotoUploaded, setSecondPhotoUploaded] = useState(false);
  const context = useContext(IdentityContext);

  const [state] = useActor(context);

  const methods = useForm<IFormValues>({
    defaultValues: {
      backImg: undefined,
      frontImg: undefined,
      idType: state?.context?.idType,
    },
    mode: 'all',
    resolver: yupResolver(SCHEMA),
  });

  const { t } = useTranslation();

  const handleOnSubmit = useCallback(
    (v: IFormValues) => {
      context?.send('NEXT', { ...fp.omit('idType')(v) });
    },
    [context?.send],
  );

  const idType = state?.context?.idType;
  const isSdk = state?.context?.uploadMethod === 'sdk';

  const isDisabled = () => {
    if (idType === 'passport') {
      if (firstPhotoUploaded) {
        return false;
      }
    }

    if (idType === 'id') {
      if (firstPhotoUploaded && secondPhotoUploaded) {
        return false;
      }
    }
    return true;
  };

  return (
    <FormProvider {...methods}>
      <form
        className="relative flex flex-1 flex-col justify-center gap-4"
        onSubmit={methods.handleSubmit(handleOnSubmit)}
      >
        <FileField
          className={cx({ hidden: isSdk })}
          idType={idType}
          label={t(`node.identity-document.fs.${idType}.front`)}
          labelClassName="text-center !text-black mb-2 !font-bold"
          labelFontSize="xl"
          name="frontImg"
          onSuccess={() => {
            setFirstPhotoUploaded(true);
          }}
        />

        {!firstPhotoUploaded && (
          <SDKField
            className={cx({
              hidden: state?.context?.uploadMethod === 'file-system',
            })}
            label={t(`node.identity-document.sdk.${idType}.front`)}
            name="frontImg"
            onSuccess={(file) => {
              methods.setValue('frontImg', file);
              setTimeout(() => {
                setFirstPhotoUploaded(true);
              }, 500);
            }}
          />
        )}

        {state?.context?.uploadMethod !== 'file-system' &&
          firstPhotoUploaded && <p>Frente cargado con éxito</p>}
        {state?.context?.uploadMethod !== 'file-system' &&
          secondPhotoUploaded && <p>Reverso cargado con éxito</p>}

        {idType === 'id' && firstPhotoUploaded && !secondPhotoUploaded && (
          <SDKField
            className={cx({
              hidden: state?.context?.uploadMethod === 'file-system',
            })}
            label={t(`node.identity-document.sdk.${idType}.back`)}
            name="backImg"
            onSuccess={(file) => {
              methods.setValue('backImg', file);

              setTimeout(() => {
                setSecondPhotoUploaded(true);
              }, 300);
            }}
          />
        )}

        {idType !== 'passport' ? (
          <FileField
            className={cx({ hidden: isSdk })}
            label={t(`node.identity-document.fs.${idType}.back`)}
            labelClassName="text-center !text-black mb-2 !font-bold"
            labelFontSize="xl"
            name="backImg"
            onSuccess={() => {
              setSecondPhotoUploaded(true);
            }}
          />
        ) : null}

        <div className="flex justify-between">
          <Button
            className="px-6 text-white"
            onClick={() => context?.send('BACK')}
            primaryColor="#C4C4C4"
            size="sm"
            variant="solid"
          >
            {t('button.back')}
          </Button>

          <Button
            className="px-6 text-white"
            isDisabled={isDisabled()}
            primaryColor="#6344FF"
            size="sm"
            type="submit"
            variant="solid"
          >
            {t('button.next')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
