/* eslint-disable import/no-extraneous-dependencies */
import '@cincel.digital/design-system/dist/styles/bundle.css';
import 'assets/css/index.css';
import './i18n';

import { ChakraProvider } from '@chakra-ui/react';
import { i18n } from 'locales';
import { HomePage } from 'pages/Home';
import { NotFoundPage } from 'pages/NotFound';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';

const ROUTER = createBrowserRouter([
  {
    element: <HomePage />,
    index: true,
  },
  {
    element: <NotFoundPage />,
    path: '*',
  },
]);

const MOUNT_NODE = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const CINCEL: React.FC = (): JSX.Element => (
  <React.StrictMode>
    <I18nextProvider i18n={i18n} />
    <HelmetProvider>
      <ChakraProvider>
        <RouterProvider router={ROUTER} />
      </ChakraProvider>
    </HelmetProvider>
  </React.StrictMode>
);

MOUNT_NODE.render(<CINCEL />);

reportWebVitals();
