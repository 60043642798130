import fp from 'lodash/fp';

export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export const isValidFileFormat = fp.curry(
  (value, formats = SUPPORTED_FORMATS) => fp.includes(value)(formats),
);

export const isValidFileSize = fp.curry(
  (size, FILE_SIZE = 5 * 1024 * 1024) => (size as number) <= FILE_SIZE,
);
