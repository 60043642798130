/* eslint-disable import/no-extraneous-dependencies */
import { useToast } from '@chakra-ui/react';
import { Button, H1, Spinner } from '@cincel.digital/design-system';
import axios, { AxiosError } from 'axios';
import { IdentityContext } from 'contexts';
import { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Trans, useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { Link, useSearchParams } from 'react-router-dom';
import { TRANSLATIONS } from 'translations';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const Idle = (): JSX.Element => {
  const toast = useToast();
  const { t } = useTranslation();
  const [isUsed, setIsUsed] = useState(false);
  const [hasError, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const context = useContext(IdentityContext);

  const [sp] = useSearchParams();

  useEffect(() => {
    const fetchResults = async (uuid) => {
      setLoading(true);
      try {
        await instance.get(`/gbg/${uuid}/results`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        setLoading(false);
        setIsUsed(true);
      } catch (error) {
        const status = (error as AxiosError)?.response?.status;

        if (status === 404) {
          setIsUsed(false);
        } else if (status === 401) {
          toast({
            isClosable: true,
            position: 'top-right',
            status: 'error',
            title: t(TRANSLATIONS.ERRORS.EXPIRED),
          });
          setError(true);
        } else if (status === 403) {
          toast({
            isClosable: true,
            position: 'top-right',
            status: 'error',
            title: t(TRANSLATIONS.ERRORS.MALFORMED),
          });
          setError(true);
        } else {
          toast({
            isClosable: true,
            position: 'top-right',
            status: 'error',
            title: t(TRANSLATIONS.ERRORS.GENERAL),
          });
          setError(true);
        }

        setLoading(false);
      }
    };

    if (sp.has('uuid')) {
      localStorage.setItem('uuid', sp.get('uuid') || '');
      fetchResults(sp.get('uuid'));
    } else localStorage.removeItem('uuid');
  }, []);

  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-4">
      {!isMobile && (
        <>
          <H1 className="text-center">{t(TRANSLATIONS.DESKTOP.TITLE)}</H1>
          <div>
            <p className="text-center text-sm md:max-w-2xl">
              {t(TRANSLATIONS.DESKTOP.SUBTITLE)}{' '}
            </p>
            <QRCode
              size={200}
              style={{
                height: 'auto',
                marginTop: 20,
                maxWidth: '100%',
                width: '100%',
              }}
              value={window.location.href}
              viewBox="0 0 256 256"
            />
          </div>
        </>
      )}
      {loading && isMobile && (
        <div>
          <Spinner />
        </div>
      )}
      {!loading && isMobile && (
        <>
          <H1 className="text-center">
            {!isUsed ? t('node.idle.title') : t(TRANSLATIONS.LINK_USED.TITLE)}
          </H1>

          <p className="text-center text-sm md:max-w-2xl">
            {!isUsed && (
              <Trans i18nKey="node.idle.terms">
                <Link
                  className="font-semibold text-[#6344FF]"
                  target="_blank"
                  to="https://www.cincel.digital/aviso-de-privacidad/"
                />
              </Trans>
            )}
            {isUsed && (
              <div>
                <p>{t(TRANSLATIONS.LINK_USED.SUBTITLE)} </p>
                <Link
                  className="font-semibold text-[#6344FF]"
                  target="_blank"
                  to="https://www.cincel.digital/aviso-de-privacidad/"
                >
                  {t(TRANSLATIONS.PRIVACY_POLICY)}
                </Link>
              </div>
            )}
          </p>

          {!isUsed && (
            <Button
              className="px-6 text-white"
              isDisabled={hasError}
              onClick={() => context?.send('NEXT', { uuid: sp.get('uuid') })}
              primaryColor="#6344FF"
              size="sm"
              variant="solid"
            >
              {t('button.start')}
            </Button>
          )}
        </>
      )}
    </div>
  );
};
