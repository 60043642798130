import { H1 } from '@cincel.digital/design-system';
import { useActor } from '@xstate/react';
import axios from 'axios';
import { IdentityContext } from 'contexts';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MoonLoader } from 'react-spinners';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const Middleware = (): JSX.Element => {
  const { t } = useTranslation();
  const context = useContext(IdentityContext);
  const [state] = useActor(context);

  useEffect(() => {
    const startVerification = async () => {
      const ctx = state.context;
      const fd = new FormData();

      const fileType = ctx.idType === 'passport' ? 'PASSPORT' : 'ID';

      if (ctx?.frontImg)
        fd.append('front_side_image', ctx.frontImg, ctx.frontImg.name);
      if (ctx?.backImg) {
        fd.append('back_side_image', ctx.backImg, ctx.backImg.name);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (ctx?.frontImg) {
          fd.append('back_side_image', ctx.frontImg, ctx.frontImg.name);
        }
      }
      if (ctx?.faceImg)
        fd.append('selfie_image', ctx.faceImg, ctx.faceImg.name);
      fd.append('type', fileType);

      try {
        await instance.patch(`/gbg/${ctx?.uuid}/verification`, fd, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        context?.send('NEXT');
      } catch (error) {
        throw new Error(error as any);
      }
    };

    startVerification();
  }, []);

  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-4">
      <H1 className="text-center">{t('node.middleware.title')}</H1>

      <p className="text-center text-sm md:max-w-2xl">
        {t('node.middleware.quote')}
      </p>

      <MoonLoader color="#C4C4C4" speedMultiplier={0.5} />
    </div>
  );
};
